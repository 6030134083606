import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';

// Enums
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';
import ModalEnum from '@/ship/Enums/ModalEnum';
import { PMChartStatusEnum, PMPlanChartEnum, PMTableActionEnum } from '@/ship/Enums/ProjectManagementEnums';

// Utils
import ErrorService from '@/utils/ErrorService';
import { downloadFile } from '@/utils/FilesDownloading';
import { fromISODateToLocalDate, tooltipForContent } from '@/utils/Helper';
import ModalService from '@/ship/Services/ModalService';
import { hasAllPermissions, hasPermission } from '@/utils/Permissions';

// Interfaces

// Models
import User from '@/ship/Models/User';
import {
    IPMApprovalUsers,
    IPMChart,
    IPMChartBpc,
    IPMChartBpcRow,
    IPMChartDpc,
    IPMChartDpcExec,
    IPMChartDpcExecRow,
    IPMChartDpcRow,
    IPMChartRow,
    IPMChartVersion,
    IPMChartVersionRow,
    IPMChartWeeklyReport,
    IPMChartWeeklyReportRow,
    PMChartBpcStatuses,
    PMChartStatuses,
} from '@/ship/Models/IProjectManagement';
import IDictionary from '@/ship/Models/IDictionary';
import Project from '@/ship/Models/Project';
import { get, omit } from 'lodash';
import VContextList from '@/components/VContextList.vue';
import PMChartHistory from '@/components/ProjectManagement/PMChartHistory.vue';
import {
    getPdfPMPlanChart,
    getPMChart,
    getPMChartBpcList,
    getPMChartDpcExecList,
    getPMChartDpcList,
} from '@/utils/Api';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import PMChartEditModel from '@/ship/Models/PMChartEditModel';
import { ChartVersion } from '@/ship/Models/ProjectManagement';

type TableRow = IPMChartRow | IPMChartDpcRow | IPMChartBpcRow | IPMChartDpcExecRow | IPMChartWeeklyReportRow;

@Component({
    components: {},
})
export default class PMMixin extends Vue {
    @Action GET_USERS!: () => Promise<User[]>;
    @Action GET_ALL_PROJECTS!: () => Promise<Project[]>;
    @Action GET_CHART_BY_ID!: (params: { id: number; params?: GetRequestParams }) => Promise<IPMChart>;
    @Action UPDATE_CHART_BY_ID!: (params: { id: number; data: PMChartEditModel }) => Promise<IPMChart>;
    @Action GET_STRUCTURE!: (id: number) => Promise<ChartVersion>;
    @State user!: User;
    @State users!: User[];
    @State projects!: Project[];
    @State chart!: IPMChart;
    @State editChart!: IPMChart | null;
    @State structure!: ChartVersion | null;
    @Mutation ADD_CHART_VERSION!: (version: IPMChartVersion) => void;
    @Mutation REMOVE_CHART_VERSION_BY_ID!: (id: number) => void;
    @Getter chartVersions!: IPMChartVersionRow[];
    @Getter isStructureExist!: boolean;

    @Ref() actionsList!: VContextList;
    @Ref() documentHistory!: PMChartHistory;
    @Ref() printButton!: HTMLLinkElement;

    readonly PMChartStatusEnum = PMChartStatusEnum;
    readonly PMTableActionEnum = PMTableActionEnum;
    readonly pmChartBpcStatuses = PMChartBpcStatuses;
    readonly pmChartStatuses = PMChartStatuses;

    readonly prefix = 'page.projectManagement';

    readonly nameCol = '21rem';
    readonly flagCol = '8rem';
    readonly dateCol = '11rem';
    readonly statusCol = '11rem';
    readonly userCol = '24.5rem';
    readonly cbCol = '4.5rem';

    readonly tooltipForContent = tooltipForContent;

    maxHeight = 43;

    docUsers: IPMApprovalUsers = {
        mainEngineerId: NaN,
        branchDirectorId: NaN,
        regionalHeadId: NaN,
        constructionDirectorDeputyId: NaN,
        generalDirectorDeputyId: NaN,
    };

    loading = false;

    chartName: string = '';
    childFields: string[] = ['childTasks'];
    extractedRows: IDictionary[] = [];

    showForm = false;

    activeRow: TableRow | null = null;

    isPrinting = true;

    /**
     * @description check if mode is 'create'
     * @returns {boolean} if mode is 'create' - true, else ('view') - false
     */
    get isCreateMode(): boolean {
        return this.$route.meta?.mode === 'create';
    }

    get historyId() {
        const historyId = parseInt(this.$route.params.id);
        return historyId;
    }

    get actionOptions() {
        const options = [
            {
                label: this.$t(`page.projectManagement.charts.openDocument`),
                value: PMTableActionEnum.open,
            },
            {
                label: this.$t(`history`),
                value: PMTableActionEnum.history,
            },
        ];
        return options;
    }

    get pageActionTitle() {
        return this.$t(`${this.prefix}.action.sendForApproval`);
    }

    get isActionDisabled() {
        return !(this.activeRow && this.activeRow.selected);
    }

    get chartId(): number {
        return this.getIdFromParams(this.$route.params.id);
    }

    get docId(): number {
        return this.getIdFromParams(this.$route.params.docId);
    }

    get activeRowName() {
        let name = '';
        if (this.activeRow) {
            name = this.activeRow.name ? `"${this.activeRow.name}"` : `(ID = ${this.activeRow.id})`;
        }
        return name;
    }

    get baseVersionId() {
        if (!this.chart) return 0;

        return this.chart.versions!.data.find((version) => version.isBaseVersion)!.id;
    }

    getIdFromParams(paramsId: string | undefined): number {
        return paramsId ? parseInt(paramsId) : NaN;
    }

    extractRowData(entity: IDictionary, childFields: string[], level: number = 1): IDictionary {
        const children: IDictionary[] = [];

        for (const key of childFields) {
            const childEntities = get(entity, `${key}.data`);
            if (!Array.isArray(childEntities) || !childEntities.length) continue;
            const newLevel = level + 1;
            const extractedChildren = childEntities.map((child: IDictionary) =>
                this.extractRowData(child, this.childFields, newLevel),
            );
            children.push(...extractedChildren);
        }

        return {
            ...omit(entity, childFields),
            isOpen: true,
            level,
            children,
            selected: false,
        };
    }

    isUsersSelected<T>(chart: T, every: boolean = true): boolean {
        const keys = [
            'branchDirectorId',
            'constructionDirectorDeputyId',
            'generalDirectorDeputyId',
            'mainEngineerId',
            'regionalHeadId',
        ];

        return every ? keys.every((key) => !!chart[key as keyof T]) : keys.some((key) => !!chart[key as keyof T]);
    }

    updateApprovalUsers(doc: IPMChartBpc | IPMChartDpc | IPMChartDpcExec) {
        this.docUsers.branchDirectorId = doc.branchDirectorId;
        this.docUsers.constructionDirectorDeputyId = doc.constructionDirectorDeputyId;
        this.docUsers.generalDirectorDeputyId = doc.generalDirectorDeputyId;
        this.docUsers.mainEngineerId = doc.mainEngineerId;
        this.docUsers.regionalHeadId = doc.regionalHeadId;
    }

    updateDocUsers(document: IPMChartBpc | IPMChartDpc | IPMChartDpcExec) {
        document.branchDirectorId = this.docUsers.branchDirectorId;
        document.constructionDirectorDeputyId = this.docUsers.constructionDirectorDeputyId;
        document.generalDirectorDeputyId = this.docUsers.generalDirectorDeputyId;
        document.mainEngineerId = this.docUsers.mainEngineerId;
        document.regionalHeadId = this.docUsers.regionalHeadId;
    }

    getUser(id?: number): User | undefined {
        if (this.users) {
            const user = this.users.find((user) => user.id === id);
            return user;
        }

        return undefined;
    }

    getProject(id?: number): Project | undefined {
        if (this.projects) {
            const project = this.projects.find((project) => project.id === id);
            return project;
        }

        return undefined;
    }

    toggleShowForm() {
        this.showForm = !this.showForm;
    }

    showSuccessModal(title: string, label = this.$t('great')) {
        this.$modal.show(ModalEnum.Success, { title, label });
    }

    async loadUsers() {
        if (!this.users || !this.users.length) {
            await this.GET_USERS();
        }
    }

    async loadProjects() {
        if (!this.projects || !this.projects.length) {
            await this.GET_ALL_PROJECTS();
        }
    }

    /**
     * The `loadChart` function asynchronously loads a chart by its ID and sets the chart name if
     * successful, handling any errors that may occur.
     * @returns If the `chartId` is not defined, the function will return early and not execute the rest of
     * the code inside the function.
     */
    async loadChart() {
        if (!this.chartId) return;
        const params: GetRequestParams = {
            include: 'versions',
        };
        await this.GET_CHART_BY_ID({ id: this.chartId, params })
            .then((chart) => {
                this.chartName = chart.name!;
            })
            .catch((error) => {
                ErrorService.handleApiError(error);
            });
    }

    async loadStructure() {
        if (!this.baseVersionId) return;

        try {
            await this.GET_STRUCTURE(this.baseVersionId);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    isLoadingChart: boolean = false;
    bpcList: IPMChartBpc[] = [];
    dpcQuartList: IPMChartDpc[] = [];
    dpcExecList: IPMChartDpcExec[] = [];
    weeklyReportList: IPMChartWeeklyReport[] = [];
    /**
     * @description Loads the BPC chart rows based on the provided chart ID.
     */
    async loadChartBpcRows() {
        this.isLoadingChart = true;

        try {
            const response = await getPMChartBpcList(this.chart.id);
            const bpcList = response.data.data;
            this.bpcList = bpcList;
        } catch (error) {
            ErrorService.handleApiError(error);
        } finally {
            this.isLoadingChart = false;
        }
    }

    /**
     * @description Loads the DPC chart rows based on the provided chart ID.
     */
    async loadChartDpcRows() {
        this.isLoadingChart = true;

        try {
            const response = await getPMChartDpcList(this.chart.id);
            const dpcQuartList = response.data.data;
            this.dpcQuartList = dpcQuartList;
        } catch (error) {
            ErrorService.handleApiError(error);
        } finally {
            this.isLoadingChart = false;
        }
    }

    /**
     * @description Loads the DPC Exec chart rows based on the provided chart ID.
     */
    async loadChartDpcExecRows() {
        this.isLoadingChart = true;

        try {
            const response = await getPMChartDpcExecList(this.chart.id);
            const dpcExecList = response.data.data;
            this.dpcExecList = dpcExecList;
        } catch (error) {
            ErrorService.handleApiError(error);
        } finally {
            this.isLoadingChart = false;
        }
    }

    /**
     * @description Loads the weekly report chart rows based on the provided chart ID.
     */
    async loadChartWeeklyReportRows() {
        this.isLoadingChart = true;

        try {
            // TODO --> weekly report API
            const response = await getPMChartDpcExecList(this.chart.id);
            const weeklyReportList = response.data.data;
            this.weeklyReportList = weeklyReportList;
        } catch (error) {
            ErrorService.handleApiError(error);
        } finally {
            this.isLoadingChart = false;
        }
    }

    getCells(row: IDictionary, columns: IDictionary[]) {
        const cells: IDictionary[] = [];

        for (const column of columns) {
            const cell = {
                data: row[column.key],
                type: column.type,
                key: column.key,
            };
            cells.push(cell);
        }

        return cells;
    }

    isUser(cell: IDictionary) {
        return cell.type === 'user';
    }

    isDate(cell: IDictionary) {
        return cell.type === 'date';
    }

    isBoolean(cell: IDictionary) {
        return cell.type === 'boolean';
    }

    isStatus(cell: IDictionary) {
        return cell.type === 'status';
    }

    isCheckbox(cell: IDictionary) {
        return cell.type === 'checkbox';
    }

    isFirst(cell: IDictionary) {
        return cell.type === 'first';
    }

    isIcon(cell: IDictionary) {
        return cell.type === 'icon';
    }

    showDate(cell: IDictionary) {
        return fromISODateToLocalDate(cell.data);
    }

    showBoolean(cell: IDictionary) {
        return cell.data ? 'Да' : '-';
    }

    created() {
        this.loadUsers();
        this.loadProjects();
        this.loadChart();
        this.loadStructure();
    }

    async downloadPlanChart(id: number, type: PMPlanChartEnum, name?: string) {
        let title = '';
        switch (type) {
            case PMPlanChartEnum.bpc:
                title = this.$t(`page.projectManagement.chart.bpc.title`) as string;
                break;

            case PMPlanChartEnum.dpcQuart:
                title = this.$t(`page.projectManagement.chart.dpcQuart.title`) as string;
                break;

            case PMPlanChartEnum.dpcExec:
                title = this.$t(`page.projectManagement.chart.dpcExec.title`) as string;
                break;

            default:
                break;
        }

        const url = `/project-management/${type as string}/${id}/excel`;

        try {
            let fileName = title;
            fileName += name ? `- ${name}` : '';
            fileName += `- ${id}.xlsx`;
            const fileData = {
                url,
                name: fileName,
            };

            await downloadFile(fileData);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    /**
     * Запрос на получение печатной формы и ее открытие
     * @param {number} id - идентификатор
     */
    async printPlanChart(id: number, type: PMPlanChartEnum) {
        if (this.isPrinting) {
            this.isPrinting = false;
            await getPdfPMPlanChart(id, type as string).then((form) => {
                this.printButton.href = form.data.toString();
                this.printButton.click();
                this.isPrinting = true;
            });
        }
    }

    selectRow(rowId: number, value: boolean, rows: TableRow[]) {
        rows.forEach((row) => {
            if (row.id === rowId) {
                row.selected = !!value;
                if (value) this.activeRow = row;
            } else {
                row.selected = false;
            }
        });
    }

    showDeleteSuccessModal() {
        const title = this.$t(`${this.prefix}.chart.didDelete`, [this.activeRowName]);
        const actionLabel = this.$t('good');

        this.$modal.show(ModalEnum.Alert, { title, actionLabel });
    }
}
